@import "./Animations.scss";
@import "./Boilerpolate.scss";
@import "./Colors.scss";
@import "./flex-tools.scss";
@import "./Loaders.scss";
@import "./Navbars.scss";
@import "./Sections.scss";
@import "./Buttons.scss";
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@font-face {
    font-family: "lulo";
    src: url(../../res/fonts/FontsFree-Net-Lulo-Clean-W01-One.ttf) format("truetype");

}

html {
    scroll-behavior: smooth;
  }

.btn {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
        box-shadow: none !important; 
    }

    &:active {
        outline: none !important;
    box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    }
}

* {
    box-sizing: border-box !important;
}

body {
    color: $darkGrey !important;
    font-family: 'Raleway', sans-serif;
    
}

h2 {
    font-size: 25px;
}

h1 {
    font-size: 30px;
}

h4 {
    font-family: 'Roboto', sans-serif;
    color: $darkGrey !important;
}

p {
    font-family: 'Roboto', sans-serif;
    color: $mediumGrey;
}

h6 {
    font-size: 12px;
}