@import "./Colors.scss";

.alternative-font {

    div {
        font-family: 'Roboto', sans-serif;
    }
}

.mobile-header {
    display: none;
}

 .row-content-text{
    color: $mediumGrey !important;
    font-family: 'Roboto', sans-serif;
    line-height: 30px;
}

.owner-item {
    overflow: hidden;
    
    background-color: white;

    .owner-item-body {
        padding:20px
    }

    .owner-func {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;

        button {
            outline: none !important;
            box-shadow:  none !important;

            &:hover {
                outline: none !important;
            box-shadow:  none !important;
            }

            &:active {
                outline: none !important;
            box-shadow:  none !important;
            }

            &:focus {
                outline: none !important;
            box-shadow:  none !important;
            }
            i {
                color:$mediumGrey
            }
        }
    }

    .owner-img {
        border-radius: 50%;
        width: 60px;
        height: 60px;

    }
}



    .owner-display {
        cursor: pointer;
        padding:10px;
        transition: 0.3s;

        .owner-img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
    
        }

        
    }



.special-input {


    position: relative;

    & {

    .owner-result {
       
        z-index: 4;
        position: absolute;
        top:100%;
        left: 0;
        width: 100%;
        min-height: 100px;
        background-color: white;
        padding:10px;
        background-color: white;

        .owner-display {
            cursor: pointer;
            padding:10px;
            transition: 0.3s;

            .owner-img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
        
            }

            &:hover {
                background-color: lightgrey;
            }
        }
    }
}






}

.home, .admin {

    min-height: 100vh;

    .search-module {
        padding: 40px;
        background:#e7e8ed;
    
        .property-card {
            position: relative;
            background-color: white;

            .edit-btns {
                position: absolute;
                z-index: 6;
                top:0;
                right: 0;
                background-color: white;
            }
    
            .property-card-header {
                height: 250px;
                position: relative;
    
                .backdrop {
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                }
                .price-tag {
                    position: absolute;
                    padding: 10px;
                    bottom: 20px;
                    letter-spacing: 3px;
                    right: 0;
                    background-color: white;
                     p{
                         color: $darkGrey;
                         margin:0;
                         padding: 0;
                     }
                }
            }
            .property-card-body {
                padding: 20px;
                min-height: 200px;
                position: relative;
    
                h6 {
                    color: $primaryColor;
                }
    
                p {
                    line-height: 25px;
                }
    
                .btn-expand {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: $darkGrey;
                    border-radius: 0;
    
                    outline: none !important;
                    box-shadow: none !important;
    
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    &:hover {
                        outline: none !important;
                        box-shadow: none !important; 
                    }
    
                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }
    
                    &:active {
                        outline: none !important;
                        box-shadow: none !important;
                    }
    
                    i {
                        color: white;
                    }
                }
                
            }
    
        }
    
        .search-module-card {
            background-color: white;
            padding: 20px;
            min-height: 150px;
    
            .text-small {
                font-size: 12px;
            }
    
            .custom-select {
                box-shadow: none !important;
                outline: none !important;
                border-color: $lightGrey;
                font-family: 'Roboto', sans-serif;
    
                &:hover {
                    box-shadow: none !important;
                outline: none !important;  
                border-color: $lightGrey;
                }
    
                &:active {
                    box-shadow: none !important;
                outline: none !important;
                border-color: $lightGrey;
                }
                option {
                    font-family: 'Roboto', sans-serif;
                }
            }
    
           .custom-checkbox {
               label {
                font-family: 'Roboto', sans-serif;
               }
           }
        }
    }

    .custom-right-arrow {
        position: absolute !important;
        right: 0em;
        top:50%;
        z-index: 1;
        display: inline-block;
        color: rgb(200,200,200);
        padding: 6px;
        opacity: 0.8;
        cursor: pointer;
        font-weight: lighter;
        font-size: 50px;
        transform: translate(0,-50%);
      }
      .custom-right-arrow:hover {
        opacity: 0.5;
      }
      .custom-left-arrow {
        position: absolute !important;
        left: 0em;
        top:50%;
        z-index: 1;
        display: inline-block;
        padding: 6px;
        opacity: 0.8;
        cursor: pointer;
        font-size: 50px;
        font-weight: lighter;
        color: rgb(200,200,200);
        transform: translate(0,-50%);
      }
      .custom-left-arrow:hover {
        opacity: 0.5;
      }

    .admin-section {

        .custom-list {
            padding: 0 !important;
    
            li {
                i {
                    color: $primaryColor;
                }
                margin-top: 15px;
    
                span {
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    color: $mediumGrey;
                }
            }
        }

        .custom-list {
            li {
                position: relative;

                .delete-module {
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transition: 0.2s;
    
                    button {
                        i {
                            color:white !important;
                            font-size:20px
                        }
                    }
                }
    
                &:hover {
                    .delete-module {
                        display: block;
                        position: absolute;
                        background-color: rgba(0,0,0,0.5);
                        width: 100%;
                        height: 100%;
                        top:0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 1;
                        transition: 0.2s;
    
                        button {
                            i {
                                color:white !important;
                                font-size:20px;
                            }
                        }
                    }
                }
            }
        }

        .img-add {
            width: 100px;
            height: 100px;
            border:1px dotted $mediumGrey;
            align-items: center;
            border-radius: 50%;
            position: relative;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }

        label {
            color: $primaryColor;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    margin-bottom: 5px;
        }


        .custom-select {
            font-family: 'Roboto', sans-serif;
            outline: none !important;
            box-shadow: none !important;
            border-color: $primaryColor;

            &:active {
                outline: none !important;
            box-shadow: none !important;
            border-color: $primaryColor;
            }

            &:hover {
                outline: none !important;
                box-shadow: none !important;
            }


        }

        .presentation-img {
            width:500px;
            height: 250px;
            position: relative;
            border:1px solid $mediumGrey;

            display: flex;
            justify-content: center;
            align-items: center;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }

        .photo-elements {

            .photo-element {
                width: 375px;
                height: 150px;
                border-radius: 3px;
                position: relative;
                border:1.5px solid $lightGrey;
    
                .btn-clear-img {
                    position: absolute;
                    bottom: 100%;
                    left: 100%;
                    background-color: $primaryColor;
                    color: white;
                    border: none !important;
                    outline: none !important;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translate(-50%,50%);
                }
    
                .progress {
                    position: absolute;
                    bottom: 5px;
                    left: 50%;
                    transform: translate(-50%,0);
                    z-index: 2;
                    width: 70%;
                    .progress-bar {
                        background-color: $primaryColor
                    }
                }
            }
            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
            .photo-element-add {
                position: relative;
                height: 150px;
                width: 375px;
                border: 1.5px dashed $primaryColor;
                border-radius: 3px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
    
                 i{
                     font-size: 35px;
                     color: $darkGrey;
                 }
            }
        }

        .min-width-300 {
            min-width: 300px;
        }

        .about-us-image {
            position: relative;
            min-height: 400px;
            width: 100%;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }
        

        .faq-add {
            textarea {
                min-height: 100px !important;
                min-width: 600px;
            }

            input {
                min-width: 600px;
            }
        }

        .row-title {
            position: relative !important;
            .delete-module {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: 0.2s;

                button {
                    i {
                        color:white !important;
                        font-size:50px
                    }
                }
            }

            &:hover {
                .delete-module {
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s;

                    button {
                        i {
                            color:white !important;
                            font-size:50px;
                        }
                    }
                }
            }
        }

        .review {
            position: relative;

            .delete-module {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: 0.2s;

                button {
                    i {
                        color:white !important;
                        font-size:50px
                    }
                }
            }

            &:hover {
                .delete-module {
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s;

                    button {
                        i {
                            color:white !important;
                            font-size:50px;
                        }
                    }
                }
            }
        }

        .reviews-add {

            .author-img {
                position: relative;
                border:1px solid $mediumGrey;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;



                .custom-file {
                    position: absolute;
                    top:0;
                    left: 0;
                    height: 100% !important;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                    background-color: transparent !important; 
                    cursor: pointer;
        
                    .custom-file-input {
                        height: 100%;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
                    }
                    .custom-file-label {
                        height:100% !important;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
        
                        &:after {
                            content:"";
                            background-color: transparent;
                            border: none !important;
                            cursor: pointer;
                            outline: none !important;
                    box-shadow: none !important;
                        }
                    }
                }

            }

            min-width: 300px;
            textarea {
                min-height: 100px !important;
                min-width: 300px;
            }

            input {
                min-width: 300px;
            }
        }
        .carousel-img {
            width: 500px;
            height: 200px;
            position: relative;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }

            .delete-module {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: 0.2s;

                button {
                    i {
                        color:white !important;
                        font-size:50px
                    }
                }
            }

            &:hover {
                .delete-module {
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s;

                    button {
                        i {
                            color:white !important;
                            font-size:50px;
                        }
                    }
                }
            }
        }

        

        .service-card {
            transform: scale(1);

            &:hover {
                transform: scale(1);
            }
        }

        .generic-add {
            padding: 10px;
            border: 1px solid rgb(168, 168, 168);
        }

        .offer-card {
            position: relative;
            background-color: white;
            width: 300px;

            .delete-module {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: 0.2s;

                button {
                    i {
                        color:white !important;
                        font-size:50px
                    }
                }
            }

            &:hover {
                .delete-module {
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s;

                    button {
                        i {
                            color:white !important;
                            font-size:50px;
                        }
                    }
                }
            }

            .offer-card-head {
                height: 200px;
            }

            .tag-number {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primaryColor;
                

                p {
                    color: white;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 20px;
                }
            }

            .offer-card-body {
                h4 {
                    font-size: 18px;
                }

                textarea {
                    min-height: 200px !important;
                }
            }
        }

        .offer-add {
            width: 300px;
            .offer-add-head {
                position: relative;
                height: 200px;
                border:1px solid $mediumGrey;
                display: flex;
                justify-content: center;
                align-items: center;

                .custom-file {
                    position: absolute;
                    top:0;
                    left: 0;
                    height: 100% !important;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                    background-color: transparent !important; 
                    cursor: pointer;
        
                    .custom-file-input {
                        height: 100%;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
                    }
                    .custom-file-label {
                        height:100% !important;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
        
                        &:after {
                            content:"";
                            background-color: transparent;
                            border: none !important;
                            cursor: pointer;
                            outline: none !important;
                    box-shadow: none !important;
                        }
                    }
                }
            }

            .offer-add-body {
                textarea {
                    min-height: 200px !important;
                }
            }
        }

        .carousel-img-add {
            width: 500px;
            height: 200px;
            border: 1px solid rgb(168, 168, 168);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            .custom-file {
                position: absolute;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
    
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
    
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }

        .mini-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 180px;
            min-height: 180px;

            .delete-module {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: 0.2s;

                button {
                    i {
                        color:white !important;
                    }
                }
            }

            &:hover {
                .delete-module {
                    display: block;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    transition: 0.2s;

                    button {
                        i {
                            color:white !important;
                        }
                    }
                }
            }
            i {
                font-size: 50px;
                color: $primaryColor;
            }
    
            p {
                font-size: 20px;
                color: $primaryColor;
            }
        }

        .service-card {
            min-width: 300px;

            .service-card-header {
                position: relative !important;

                .custom-file {
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                    background-color: transparent !important; 
                    cursor: pointer;
        
                    .custom-file-input {
                        height: 100%;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                        width: 100%;
                    box-shadow: none !important;
                    }
                    .custom-file-label {
                        height:100% !important;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
                    width: 100%;
        
                        &:after {
                            content:"";
                            background-color: transparent;
                            border: none !important;
                            cursor: pointer;
                            outline: none !important;
                    box-shadow: none !important;
                        }
                    }
                }
            }
        }

        .mini-card-add {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 180px;
            border:1px solid rgb(168, 168, 168);
            padding: 10px;
        }

        .form-control {
            font-family: 'Roboto', sans-serif;
        }

        textarea {
            resize: none !important;
            min-height: 300px;
        }
    }

    .admin-panel {
        padding: 40px;
        padding-top: 80px;
        min-height: 600px;
    }
    .auth-form {
        min-height: 600px;
        padding: 40px;
        padding-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .form-group {
            min-width: 400px;
            .form-control {
                font-family: 'Roboto', sans-serif;
                font-weight: normal;
                font-weight: lighter;
            }
        }
    }

   
    .service-card {
        min-height: 600px;
        transition: 0.3s;
        background-color: white !important;

        .service-card-header {
            height: 400px;
        }

       .service-card-body {
          

           p {
               font-size: 18px;
           }
       }

       &:hover {
           transform: scale(1.02);
       }
    }

    .mini-card {
        
        width: 180px;
        i {
            font-size: 50px;
            color: $primaryColor;
        }

        p {
            font-size: 20px;
            color: $primaryColor;
        }
    }

    .about-us {

       
        .paragraph {
            font-size: 18px;
        }
    }

    .services {
        background:#e7e8ed;
        padding: 40px;
        padding-top: 80px;

        .offer-card {
            position: relative;
            background-color: white;
            width: 300px;

            .offer-card-head {
                height: 200px;
            }

            .tag-number {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primaryColor;
                

                p {
                    color: white;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 20px;
                }
            }

            .offer-card-body {
                h4 {
                    font-size: 18px;
                }
            }
        }
    }

    .feedback {
        padding: 40px;
        padding-top: 80px;

        .quotes-root-d5c01ba31d1be36c3fcaf96e469e608b {

            .quotes-left-b9a289c896d7611b9612001316936ec3 {
               color: black !important;
               &::after {
                   color: black !important;
                   background-color: black !important;
               }

               &::before {
                color: black !important;
                background-color: black !important;
            }
            }

            .quotes-right-3a22d0066add996920a2902977634556 {
               color:black !important;

               &::after {
                color: black !important;
                background-color: black !important;
            }

            &::before {
                color: black !important;
                background-color: black !important;
            }
            }
        }
    }

    .faq {
        padding: 40px;
        padding-top: 80px;
    }

    hr {
        margin-left: 40px;
        margin-right: 40px;
    }

}

.services {

    min-height: 100vh;

    .mini-card {
        
        width: 180px;
        i {
            font-size: 50px;
            color: $primaryColor;
        }

        p {
            font-size: 20px;
            color: $primaryColor;
        }
    }
    
    .services-parallax {
        position: relative;

        .services-panel {
            position: absolute;
            top:50%;
            left: 0;
            transform: translate(0,-50%);
            background-color: rgba(255,255,255,0.9);
            min-height: 300px;
            padding: 40px;
            width: 40%;

            h1 {
                color: $primaryColor;
            }

            p {
                font-size: 20px;
            }
        }
    }

    .services-items {
        padding: 40px;
        padding-top: 80px;

        .offer-card {
            position: relative;
            background-color: white;
            width: 300px;

            .offer-card-head {
                height: 250px;
            }

            h4 {
                color: $primaryColor;
            }

            .tag-number {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primaryColor;

               
                

                p {
                    color: white;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 20px;
                    
                }
            }
        }
    }
}


.terms-and-conditions {
    min-height: 400px;
    padding:40px;

    .terms-and-conditions-text {
        color:$darkGrey;
        text-align: justify;
    }

    .form-group {
        width: 100%;
    }
    .textarea-terms {
        min-height:500px;
        min-width: 100%;
        max-height: 100% !important;
    }
}

.rents-and-sales, .rent-and-sell, .contact {

    min-height: 100vh;

    .custom-list {
        padding: 0 !important;

        li {
            i {
                color: $primaryColor;
            }
            margin-top: 15px;

            span {
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                color: $mediumGrey;
            }
        }
    }
    .services-parallax {
        position: relative;

        .services-panel {
            position: absolute;
            top:50%;
            right: 0;
            transform: translate(0,-50%);
            background-color: rgba(255,255,255,0.9);
            height: 300px;
            padding: 40px;
            width: 40%;

            h1 {
                color: $primaryColor;
            }

            p {
                font-size: 20px;
            }
        }
    }

    .search-section {
        padding: 40px;
    padding-top: 80px; 
}

.search-module {
    padding: 40px;
    background:#e7e8ed;

    .property-card {
        background-color: white;

        .property-card-header {
            height: 250px;
            position: relative;

            .backdrop {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.2);
            }
            .price-tag {
                position: absolute;
                padding: 10px;
                bottom: 20px;
                letter-spacing: 3px;
                right: 0;
                background-color: white;
                 p{
                     color: $darkGrey;
                     margin:0;
                     padding: 0;
                 }
            }
        }
        .property-card-body {
            padding: 20px;
            min-height: 200px;
            position: relative;

            h6 {
                color: $primaryColor;
            }

            p {
                line-height: 25px;
            }

            .btn-expand {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $darkGrey;
                border-radius: 0;

                outline: none !important;
                box-shadow: none !important;

                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                &:hover {
                    outline: none !important;
                    box-shadow: none !important; 
                }

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }

                &:active {
                    outline: none !important;
                    box-shadow: none !important;
                }

                i {
                    color: white;
                }
            }
            
        }

    }

    .search-module-card {
        background-color: white;
        padding: 20px;
        min-height: 150px;

        .text-small {
            font-size: 12px;
        }

        .custom-select {
            box-shadow: none !important;
            outline: none !important;
            border-color: $lightGrey;
            font-family: 'Roboto', sans-serif;

            &:hover {
                box-shadow: none !important;
            outline: none !important;  
            border-color: $lightGrey;
            }

            &:active {
                box-shadow: none !important;
            outline: none !important;
            border-color: $lightGrey;
            }
            option {
                font-family: 'Roboto', sans-serif;
            }
        }

       .custom-checkbox {
           label {
            font-family: 'Roboto', sans-serif;
           }
       }
    }
}

}





.property-view {
    min-height:100vh;

    .property-view-body {
        padding: 40px;
        background-color: $lightGrey;;

        .property-view-card {
            background-color: white;
            padding: 20px;

            

            .key-value-pair {

                .key {
                    color: $primaryColor;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }

               
            }
        }
    }
    .main-img {
        height: 600px;
        width: 100%;
        position: relative;

       

        .services-panel {
            position: absolute;
            left: 0;
            top:40px;
            background-color: rgba(255,255,255,0.9);
            padding: 20px;
            width: 30%;
            h6 {
                color: $primaryColor;
            }

            .price {
                color: $primaryColor;
            }

            h1 {
                color: $primaryColor;
            }

            p {
                font-size: 20px;
            }
        }
    }
}


.search-bar {

     max-width: 100%;
    .form-control {
        outline: none !important;
        box-shadow: none !important;
        font-family: 'Roboto', sans-serif;
        border: none;

        &:hover {
            outline: none !important;
            box-shadow: none !important;
        }

        &:active {
            outline: none !important;
        box-shadow: none !important;
        }

        &:focus {
            outline: none !important;
        box-shadow: none !important;
        border-color: $primaryColor !important;
        }
    }

    .input-group-text {
        background-color: white;
        border: none;
    }
}

.contact-panel {
    transition: 0.4s;
    overflow: hidden;
    background-color: white;

    &.open {
    position: fixed;
    top:105.6px;
    right: 0;
    background-color: rgba(255,255,255,0.9);
    padding: 20px;
    padding-left: 50px;
    width: 300px;
    z-index: 999;
    height: 120px;

    .img-owner {
        width: 60px;
        height: 60px;
    }

    .row {
        transition-delay: 2s;
    }
    }

    &.close {
        position: fixed;
        top:105.6px;
        right: 0;
        background-color: rgba(255,255,255,0.9);
        padding: 0;
        width: 30px;
        z-index: 999;
        height: 120px;
    
        .row {
            display: none;
            transition-delay: 2s;
        }
        }

    .open-close-button {
        position: absolute;
        left: 0;
        background-color: $primaryColor;
        top:0;
        height: 100%;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
            color: white;
            
        }
    }

    
}

.contact-panel-2 {
    padding: 20px;
    background-color: white;
    position: fixed;
    top:50%;
    right: 0;
    transform: translate(0,-50%);
    z-index: 999;
}

.padding-top {
    padding-top: 40px;
}

.padding-complete {
    padding: 40px;
    padding-top: 80px;
}


.contact-module {
    padding: 40px;
    background-color: $lightGrey;

    h6 {
        span {
            font-size: 20px;
        }
    }

    .form-control {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
        border-radius: 0 !important;
        font-family: 'Roboto', sans-serif;

        &:hover {
            outline: none !important;
        box-shadow: none !important;

        }

        &:active {
            outline: none !important;
        box-shadow: none !important;
        }

        &:focus {
            outline: none !important;
        box-shadow: none !important;

        }
    }

    .form-group {
        width:100%;
    }

    textarea.form-control {
        min-height:100px;
        height: 100%;
        resize: none;
        width: 100%;
    }

    .bp3-datetimepicker{
        width: auto !important;
    }

    
}


@media screen and (max-width:800px){
    .parallax-main {
        display: none;
    }

    .admin {

        .admin-panel {
            padding: 40px;
            padding-top: 80px;
            min-height: 600px;
        }

        .auth-form {
            padding: 40px;
            padding-top: 80px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
    
            .form-group {
                min-width: 100%;
                .form-control {
                    font-family: 'Roboto', sans-serif;
                }
            }
        }
    }

    .contact-panel {
        
        transition: 0.4s;
        overflow: hidden;
 
    
        &.open {
        position: fixed;
        top: 100% !important;
        right: 0;
        background-color: rgba(255,255,255,0.9);
        padding: 20px;
        padding-left: 50px;
        width: 300px;
        z-index: 999;
        height: 120px;
        transform: translate(0,-150%);
    
        .img-owner {
            width: 60px;
            height: 60px;
        }
    
        .row {
            transition-delay: 2s;
        }
        }
    
        &.close {
            position: fixed;
            top: 100% !important;
            right: 0;
            background-color: rgba(255,255,255,0.9);
            padding: 0;
            width: 30px;
            z-index: 999;
            transform: translate(0,-150%);
            height: 120px;
        
            .row {
                display: none;
                transition-delay: 2s;
            }
            }
    
        .open-close-button {
            position: absolute;
            left: 0;
            background-color: $primaryColor;
            top:0;
            height: 100%;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i {
                color: white;
                
            }
        }
    
        
    }

    .mobile-header {
        display: block;
        color: $primaryColor;
    }

    .col-sm-4 {
        padding: 0 !important;
        margin-top: 20px;
    }

    .search-section {
        padding: 40px;
    padding-top: 40px !important;  
}

.padding-complete {
    padding: 40px;
    padding-top: 40px !important;
}

.property-view {
    .main-img {
        height: 600px;
        width: 100%;
        position: relative;

       

        .services-panel {
            position: absolute;
            left: 0;
            top:40px;
            background-color: rgba(255,255,255,0.9);
            padding: 20px;
            width: 100%;
            h6 {
                color: $primaryColor;
            }

            .price {
                color: $primaryColor;
            }

            h1 {
                color: $primaryColor;
            }

            p {
                font-size: 20px;
            }
        }
    }
}


}