@import "./Colors.scss";

.form-control {
    outline: none !important;
    box-shadow: none !important;
    

    &:active {
        outline: none !important;
    box-shadow: none !important;
    }

    &:hover {
        outline: none !important;
    box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    border-color: $primaryColor;
    }
}