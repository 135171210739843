@import "./Colors.scss";

.btn-custom-1 {
    background-color: $darkGrey;
    color: white;
    outline: none !important;
    box-shadow: none !important;
   
    &:hover {
        color: white;
    }
   
}

.btn-back {
    position: absolute;
    top:20px;
    left: 20px;

    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
    box-shadow: none !important;
    }

    &:active {
        outline: none !important;
    box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    }
}

.btn-logout {
    position: absolute;
    top:20px;
    right: 20px;

    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
    box-shadow: none !important;
    }

    &:active {
        outline: none !important;
    box-shadow: none !important;
    }

    &:focus {
        outline: none !important;
    box-shadow: none !important;
    }
}