@import "./Colors.scss";

.flex-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.flex-sb-2 {
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;

    

  
}
