
@import "./Colors.scss";

.navbar {
   
    padding-top: 0 !important;
    padding-bottom:  0 !important;

   
    .logo {
        width: 200px;
    }

    .navbar-nav {
        .nav-item {
            a {
                color: $darkGrey;
                transition: 0.3s;
               
                

                i {
                    transition: 0.3s;
                    font-size: 18px;
                }

                span {
                    transition: 0.3s;
                    font-size: 16px;
                }

                &.active-nav {
                  
                    background:#f5f5f5;
                }

                &:hover {

                    i {
                        color: $primaryColor;
                    }

                    span {
                        color: $primaryColor;
                    }

                }
            }
        }
    }
}


ul {
    padding:0;
    margin:0
   }
   li {
    list-style:none
   }
   a:focus,a:hover {
    text-decoration:none;
    -webkit-transition:.3s ease;
    -o-transition:.3s ease;
    transition:.3s ease
   }
   a:focus {
    outline:0
   }
   img {
    max-width:100%
   }
   p {
    font-size:16px;
    line-height:30px;
    color:#898b96;
    font-weight:300
   }
   h4 {
    font-family:Rubik,sans-serif;
    margin:0;
    font-weight:400;
    padding:0;
    color:#363940
   }
   .footer-area {
   a {
    color:$primaryColor;
    font-family: 'Roboto', sans-serif;
   }
}
   .no-padding {
    padding:40px !important;
   }
   .go_top {
       display: none;
    line-height:40px;
    cursor:pointer;
    width:40px;
    background:$primaryColor !important;
    color:#fff;
    position:fixed;
    -webkit-box-shadow:0 4px 4px rgba(0,0,0,.1);
    box-shadow:0 4px 4px rgba(0,0,0,.1);
    -webkit-border-radius:50%;
    border-radius:50%;
    right:-webkit-calc((100% - 500px)/ 2);
    right:calc((100% - 1140px)/ 2);
    z-index:111;
    bottom:80px;
    text-align:center
   }
   .go_top span {
    display:inline-block
   }
   .footer-big {
    padding:105px 0 65px 0
   }
   .footer-big .footer-widget {
       margin: 0 !important;
   }
   .footer--light {
    background:$darkGrey2;
   }
   .footer-big .footer-menu ul li a,.footer-big p,.footer-big ul li {
    color:#898b96
   }
   .footer-menu {
    padding: 20px !important;
   }
   .footer-menu ul li a {
    font-size:15px;
    line-height:32px;
    -webkit-transition:.3s;
    -o-transition:.3s;
    transition:.3s
   }
   .footer-menu ul li a:hover {
    color:$primaryColor;
   }
   .footer-menu--1 {
    width:100%
   }
   .footer-widget-title {
    line-height:42px;
    margin-bottom:10px;
    font-size:18px;
    color: $primaryColor;
   }
   .mini-footer {
    background:#192027;
    text-align:center;
    padding:40px !important; 
   }
   .mini-footer p {
    margin:0;
    line-height:26px;
    font-size:15px;
    color:#999
   }
   .mini-footer p a {
    color:$primaryColor;
   }
   .mini-footer p a:hover {
    color:$primaryColor;
   }
   .widget-about img {
    display:block;
    margin-bottom:30px
   }
   .widget-about p {
    font-weight:400
   }
   .widget-about .contact-details {
    margin:30px 0 0 0
   }
   .widget-about .contact-details li {
    margin-bottom:10px
   }
   .widget-about .contact-details li:last-child {
    margin-bottom:0
   }
   .widget-about .contact-details li span {
    padding-right:12px
   }
   .widget-about .contact-details li a {
    color:$primaryColor;
   }
   @media (max-width:991px) {
    .footer-menu {
     padding-left:0
    }
   }

   