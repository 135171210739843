@import "./Colors.scss";

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}